<template>
  <article id="wrap">
    <header>
      <ul class="page_head head_green">
        <li class="menuL"><a @click="back"><img src="@/assets/img/icon_back.png"></a></li>
        <li class="menuC"><h1 class="page_head_h1">月額登録</h1></li>
        <li class="menuR"></li>
      </ul>
    </header>
    <section class="contents foot_fixed_contents">
      <div class="contentInner">
        <div class="white_bg_area">
          <template v-if="talk_room">
            <div class="">
              <div class="bold" v-if="!isSubscriptionExist">{{ talk_room.name }} に登録します</div>
              <div class="bold" v-if="isSubscriptionExist">{{ talk_room.name }} を更新します</div>
              <div class="mt_5 mb_15"></div>
            </div>
            <div class="">
              <div class="bold">『すてきなせかい』とはサブスクリプション型の月額課金サービスです。</div>
              <div class="mt_5 mb_15">
                住民になることで、あなたの好きな『マスター』やそのファンといっぱいお話ができます！<br>
                好きなものを好きなように楽しめるすてきなせかいへ、いざしゅっぱーつ！<br>
                <br>
                ※ご入会に関する詳細については<router-link :to="{name:'rule'}">利用規約</router-link>のページをご参照ください。<br>
                利用規約についてご同意いただけましたら「クレジットカード登録」よりクレジットカード情報の入力画面へとお進みください。<br>
                クレジットカードのご登録をもってご入会のお手続きとなります。あらかじめご了承ください。
              </div>
            </div>
            <div class="bt_form mtb_20">
              <PaymentButton interval="month" title="クレジットカード登録"
                             :onTokenCreatedSubscription="onTokenCreatedSubscription"
                             :onTokenFailedSubscription="onTokenFailedSubscription">
              </PaymentButton>
            </div>
<!--            <hr>-->
<!--            <PaymentJsButton interval="month" title="クレジットカード登録"-->
<!--                             :onTokenCreatedSubscription="onTokenCreatedSubscription"-->
<!--                             :onTokenFailedSubscription="onTokenFailedSubscription"></PaymentJsButton>-->
          </template>
        </div><!-- .white_bg_area -->
      </div><!--.contentInner-->
    </section><!--.contents-->
    <AppFooter fixed></AppFooter>
  </article>
</template>

<script>
import AppFooter from '@/components/AppFooter';
import PaymentButton from '@/components/PaymentButton';
// import PaymentJsButton from '@/components/PaymentJsButton';
export default {
  name: 'Subscription',
  components: {
    AppFooter,
    PaymentButton,
    // PaymentJsButton
  },
  data () {
    return {
      talk_room: null,
    }
  },
  computed: {
    hasJoin () {
      const index = this.$store.state.loginUser.talk_rooms.findIndex(talk_room => {
        return talk_room.id == this.talk_room.id
      });
      return index > -1;
    },
    /**
     * 過去の支払い存在フラグ
     * @returns {boolean}
     */
    isSubscriptionExist () {
      const user_talk_room = this.$store.state.loginUser.talk_rooms.find(talk_room => talk_room.id == this.talk_room.id);
      if (!user_talk_room || !user_talk_room.subscription) return false;
      if (!user_talk_room.subscription.last_paid_at) return false;
      return true;
    },
  },
  created () {
    this.initialize();
  },
  methods: {
    async initialize () {
      const response = await this.$http.get(`talk_room/${this.$route.params.talk_room}`);
      this.talk_room = response.data;

      // 制限人数チェック
      const payment_plan = this.talk_room.payment_plans.find(talk_room => talk_room.talk_room_id == this.talk_room.id);
      if (payment_plan.limit_user && payment_plan.user_count >= payment_plan.limit_user) {
        this.$router.push({
          name: 'artist-detail', params: {
            artist: this.talk_room.artist_id,
          },
        });
        return;
      }
    },
    next () {
      this.$router.push({
        name: 'subscription-end', params: {
          talk_room: this.$route.params.talk_room,
          payment_plan: this.$route.params.payment_plan,
        },
      });
    },
    async onTokenCreatedSubscription(token=null) {
      return new Promise( (resolve, reject) => {
        if (!token) token = document.getElementsByName('payjp-token')[0].value;
        const options = {token};
        const url = `talk_room/${this.$route.params.talk_room}/${this.$route.params.payment_plan}/subscribe`;
        this.$http.post(url, options).then((response) => {
          if (response.data.success === 'OK') {
            this.$store.commit('setLoginUser', response.data.user);
            this.next();
            resolve();
          } else {
            this.$toasted.error('決済に失敗しました', {
              position: 'top-center',
              duration: 2000,
            });
            reject();
          }
        });
      });
    },
    onTokenFailedSubscription() {
      console.log('fails');
      // 処理
      this.$toasted.error('決済に失敗しました', {
        position: 'top-center',
        duration: 2000,
      });
    },
    back () {
      this.$router.go(-1);
    }
  },
}
</script>